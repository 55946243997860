<template>
	<div :style="'background: ' + webset['homebakcolor']" class="home-wrapper">
	  <search :logo="webset.logo" :webset="webset" leimulist="topmenu" />
  
	  <!-- Banner Section -->
	  <div>
		<div class="banner w1200">
		  <el-carousel trigger="click" height="400px">
			<el-carousel-item v-for="(item, index) in banner" :key="index">
			  <img :src="item.image" alt="">
			</el-carousel-item>
		  </el-carousel>
		</div>
  
		<!-- Terms and Conditions Section -->
		<div class="terms">
		  <p>Unique Fencing Terms and Conditions</p><br>
		  <p>Terms and Conditions:<br>To shop on this online store, you must be 18 years old or above. If you are under 18, you need to shop on this online store accompanied by a parent or guardian.</p>
		  <p><br></p>
		  <p>Delivery Policy:<br>Our goal is to deliver the products to the delivery address specified in your order and on the delivery date we specify at the checkout of your order (updated in the order confirmation);<br>If we expect to be unable to deliver on the expected delivery date, we will try to notify you.<br>However, to the extent permitted by law, we accept no liability for any loss, liability, cost, damage, charge, or expense caused by any delay in delivery.</p>
		  <p><br></p>
		  <p>Refund/Return/Order Cancellation Policy:<br>All products are non-refundable and non-returnable. If for any reason you are not 100% satisfied with the product, you can return it within 30 days of ordering and it must comply with our return policy.<br>Returned products must be unused, product tags must not be tampered with or removed, and the product should be placed in its original packaging box or bag (if applicable).<br>This policy applies only to items on this online store that are not on the non-returnable brands/products list.</p>
		  <p><br></p>
		  <p>Course Refund Policy:<br>Within 14 days after purchasing the course, the balance can be fully refunded after deducting the attended classes.<br>No refunds will be given after 14 days.<br>Refunds will be processed within 5-7 working days after the request.<br>All refund requests must be submitted in writing to: uniquefencing@gmail.com.</p>
		  <p><br></p>
		  <p>Checkout and Payment Process:<br>You can use the payment methods provided on the platform or use cash on delivery.</p>
		</div>
	  </div>
	</div>
  </template>

<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search'
import Product from '@/components/Product'

export default {
    name: 'Home',
    components: {Search, Product},
	data() {
		return {
			html:'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
			topmenu:[],
			//uniacid:0,
			uniacid:20,
			moduleList: [],
			leimugoodslist: [],
			banner: [],
			webset:[],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
			leimu:[],
			nowleimu:'',
		}
	},
	created() {	
			console.log('home...');
		
		 this.getuniacid()
		 	if(this.$route.query.code){
				console.log('leimu:',this.$route.query.code);
				   this.nowleimu= this.$route.query.code
				}
		
	        	 
	
		//this.getGoodsList()
	
	},
	methods: {
		// 获取首頁模块
		async getuniacid(){
			// this.uniacid= 4 ;
			 var ss = window.localStorage.getItem('webinfo');
			 if(!ss){
				 var url = window.location.href;
				 var dz_url = url.split("#")[0];
				 var www_dz_url = dz_url.split("/")[2];
				 			
				
				var centerdomain= www_dz_url.split(".");
				console.log('centerdomain:',centerdomain);
				let res
				if(centerdomain[1]=='nowaittech'){
					res = await this.$http.get('app.layout/weblist',{centerdomain:centerdomain[0]})
					console.log('websiteinfo:',res);
				}else{
					 res = await this.$http.get('app.layout/weblist',{siteroot:www_dz_url})
					console.log('websiteinfo:',res);
				}
				
				 
				 console.log('websiteinfo:',res);
				 ss = JSON.stringify(res[0])		
		
			 }
			 var ssarray = JSON.parse(ss)
			 console.log('ss',ss);
			 console.log('ssarray.ucid',ssarray['ucid']);
			 this.webset =  ssarray 
			 //this.uniacid = ssarray['ucid'] 
			 this.uniacid=20;
			 window.localStorage.setItem('uniacid',this.uniacid)
			 //window.localStorage.setItem('uniacid',24)
			 document.title = ssarray.webtitle 
			// this.getwebset()
			 this.getleimugoods()
		},
		
		async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index',uniacid:this.uniacid
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
			console.log('banner',this.banner);
		},



		//获取分类
		async getleimu(){
			let res = await this.$http.get('/app.layout/mulu',{uniacid:this.uniacid})
			if(res){
				this.menu.list = res.itemtitle.map((item,index)=>{
					let obj = {label: item, id: res.itemcode[index]}
					return  obj
				})
			}
		},
		async gettopmenu(){
			let res = await this.$http.get('/user/topmenu',{uniacid:this.uniacid})
			if(res){
				 this.topmenu= res.data 
			 
			}
		},
		async getleimugoods(){
			let res = await this.$http.get('/app.layout/getmulugoods',{
				uniacid:this.uniacid
			})
			if(res){
				console.log('gsl',res);
				   this.leimugoodslist = res 
				   this.getHomeLayout()
				    
				    this.getleimu1()
			}
		},
		
		async getwebset(){
			let res = await this.$http.get('/app.layout/getwebset?id=4',{})
			if(res){
				console.log('uniacid',this.uniacid);
				console.log('webset',res);
				   this.webset = res 
				  
			}
		},
		
		async getleimu1(){
				console.log('mulu1');
				let res = await this.$http.get('/app.layout/mulu1', {
					uniacid:this.uniacid 	 
				})
				if(res){
					console.log(res);
					this.leimulist = res 
						 
					console.log('ok');
					console.log(this.leimulist);
					let locale = window.localStorage.getItem('locale') || this.$i18n.locale
					if(locale === 'zh-CN') {
						for (let i = 0; i < res.length; i++) {
							const str = chineseLanguageLoader(res[i].title, { language: 'zh-CN' });
							this.leimulist[i].title = str;
						}
					}





				}
				
		},
		async getleimu2(){
				if(uni.getStorageSync('category_one1')){
					this.category_one=uni.getStorageSync('category_one1')
					uni.setStorageSync("category_one1", '')
					console.log('one',this.category_one);
				}
				let res = await uni.$http.get('/app.layout/mulu2', {
					 father_code:this.category_one	 
				})
					  if(res.data){
						  this.leimutitle = res.data
					      console.log(this.leimutitle);
						  console.log('ok:leimu2');
					  }
				
		},

		//点击分类
		async onClickItem(e,f) {
			this.menu.active = e
			this.goodsList=[]
			this.page=0
			//this.getGoodsList()
		},

		//获取产品列表
		async getGoodsListT(one,two) {
			this.menu.active = two
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: 0,
				size: this.size,
				category_one: one,
				category_two: two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
		    this.leimugoodslist[one]['goods']=res.data.list
		 
		},
		//获取产品列表
		async getGoodsList(category_two) {
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: this.page++,
				size: this.size,
				category_one: this.menu.active,
				category_two: category_two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
			this.pageCount = Math.ceil(data.total / this.size)
			if (this.page <= 2) this.goodsList = []
			this.goodsList = this.goodsList.concat(data.list)
		},
	}
}
</script>
<style lang="scss" scoped>
    .home-wrapper {
        min-height: 75vh;

        .banner {
            height: 400px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .terms {
            font-size: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4%;
            margin-bottom: 4%;
            max-width: 1214px; // Same as .w1200 max-width
        }
    }

    .w1200 {
        max-width: 1214px;
        margin: 0 auto;
    }
</style>
